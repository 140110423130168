import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Image from "../components/image";
import { useStaticQuery, graphql } from "gatsby";

const Header = ({ siteTitle, onClickedButton, onPopupClick }) => {
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query HeaderQuery {
        allWordpressPost {
          edges {
            node {
              title
              acf {
                phone
              }
            }
          }
        }
      }
    `
  );
  return (
    <header>
      <div className="kl-header-bg"></div>
      <div className="container container__header">
        <div className="fxb-col fxb-basis-auto">
          <div className="fxb-row site-header-row site-header-main">
            <div className="fxb-col fxb fxb-start-x fxb-center-y fxb-center-x fxb-basis-auto fxb-grow-1 fxb-sm-full site-header-col-left site-header-main-left">
              <Link to="/" className="fxb fxb-grow-1">
                <Image />
                <h1 className="fxb-grow-3">Kang Plaza</h1>
              </Link>
            </div>
            <div className="fxb-col fxb fxb-center-x fxb-center-y fxb-basis-auto fxb-sm-half site-header-col-center site-header-main-center">
              <div className="nav__wrapper">
                <div className="mainnav--sidepanel">
                  <div className="navbig">
                    <ul className="navigation">
                      <li>
                        <a href="#suites">
                          <span>Suites</span>
                        </a>
                      </li>
                      <li>
                        <a href="#gallery">
                          <span>Gallery</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={onPopupClick}>
                          <span>Contact</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="fxb-col fxb fxb-end-x fxb-center-y fxb-basis-auto fxb-sm-half site-header-col-right site-header-main-right">
              <a className="top-icon header__button__schedule" onClick={onPopupClick}>
                <i className="flaticon-calendar"></i>
              </a>

              <span className="header__phone">
                {allWordpressPost.edges.map(({ node }) => {
                  if (node.title === "General Site Info")
                    return <span key={node.id}>{node.acf.phone}</span>;
                })}
              </span>
              <a className="btn btn-theme btn-showmenu" onClick={onClickedButton}>
                <i className="flaticon-menu"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
