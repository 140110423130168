import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Map from "./Map.js";

const Footer = () => {
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query FooterQuery {
        allWordpressPost {
          edges {
            node {
              title
              acf {
                phone
                email
                address
              }
            }
          }
        }
      }
    `
  );
  const myMap =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.841815155463!2d-118.08405554902274!3d33.86796733477062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2d0ebeab5c4d%3A0xdb0df2063eb1d0eb!2s17918%20Pioneer%20Blvd%2C%20Artesia%2C%20CA%2090701%2C%20USA!5e0!3m2!1sen!2sde!4v1586857520645!5m2!1sen!2sde" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';

  return (
    <footer>
      {allWordpressPost.edges.map(({ node }) => {
        if (node.title === "General Site Info")
          return (
            <div className="container" key={node.id}>
              <div className="container__flex">
                <div className="section__column grow-3">
                  <h1 className="footer__logo">Kang</h1>
                  <div className="footer__list">
                    {node.acf.address && (
                      <div className="footer__item">
                        <span className="footer__label">Address:</span> {node.acf.address}
                      </div>
                    )}
                    {node.acf.email && (
                      <div className="footer__item">
                        <span className="footer__label">Email:</span> {node.acf.email}
                      </div>
                    )}
                    {node.acf.phone && (
                      <div className="footer__item">
                        <span className="footer__label">Phone:</span> {node.acf.phone}
                      </div>
                    )}
                  </div>
                </div>
                <div className="section__column grow-1 center">
                  <div className="location__wrrapper">
                    Location: <span className="white">{node.acf.address}</span>
                  </div>
                  <Map />
                  <span className="footer__location">
                    Our main cross streets are 183rd & Pioneer Blvd
                  </span>
                </div>
              </div>

              <div className="container__flex footer--bottom center">
                <div className="section__column grow-full">
                  Copyrights © {new Date().getFullYear()}{" "}
                  <span className="white">Kang Plaza LLC,</span> All rights reserved.
                </div>
              </div>
            </div>
          );
      })}
    </footer>
  );
};

export default Footer;
