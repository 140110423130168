import React from "react";

const MobileNav = ({ onClickClosed, onPopupClick }) => (
  <div className="mobile__wrapper">
    <a className="btn-toggle-canvas" onClick={onClickClosed}>
      <i className="flaticon-close"></i>
      <span>Close</span>
    </a>
    <ul className="mobile__navigation">
      <li>
        <a href="#suites" onClick={onClickClosed}>
          <span>Suites</span>
        </a>
      </li>
      <li>
        <a href="#gallery" onClick={onClickClosed}>
          <span>Gallery</span>
        </a>
      </li>
      <li>
        <a onClick={onPopupClick}>
          <span>Contact</span>
        </a>
      </li>
    </ul>
  </div>
);

export default MobileNav;
