import React from "react";
import { Link } from "gatsby";
const ContactForm = ({ status, onPopupClose }) => {
  return (
    <div className={status}>
      <div className="mfp-bg"></div>
      <div className="mfp-wrap">
        <div className="mfp-container" onClick={onPopupClose}>
          <div className="schedule-visit-form-wrapper">
            <button className="mfp-close" onClick={onPopupClose}></button>
            <div role="form" className="wpcf7" id="wpcf7-f285-o1" dir="ltr" lang="en-US">
              <div className="screen-reader-response"></div>

              <form
                className="wpcf7-form"
                method="POST"
                data-netlify="true"
                name="contact"
                action="/success"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="apus-schedule-visit-form">
                  <h4>Schedule a Visit</h4>
                  <div className="form-schedule-wrap">
                    <p className="form-schedule-input">
                      <span className="wpcf7-form-control-wrap text-242">
                        <input
                          type="text"
                          name="name"
                          size="40"
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required input-text"
                          placeholder="Your Name"
                        />
                      </span>
                    </p>
                    <p className="form-schedule-input">
                      <span className="wpcf7-form-control-wrap email-163">
                        <input
                          type="email"
                          name="email"
                          size="40"
                          className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email input-text"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Your Email"
                        />
                      </span>
                    </p>
                  </div>
                  <div className="form-schedule-wrap">
                    <p className="form-schedule-input">
                      <span className="wpcf7-form-control-wrap date-798">
                        <input
                          type="date"
                          name="date"
                          className="wpcf7-form-control wpcf7-date wpcf7-validates-as-date input-text"
                          aria-invalid="false"
                        />
                      </span>{" "}
                    </p>
                    <p className="form-schedule-input">
                      <span className="wpcf7-form-control-wrap text-242">
                        <input
                          type="text"
                          name="phone"
                          size="40"
                          className="wpcf7-form-control wpcf7-text input-text"
                          aria-invalid="false"
                          placeholder="Phone Number"
                        />
                      </span>
                    </p>
                  </div>
                  <div className="form-schedule-wrap-end">
                    <p className="form-schedule-input">
                      <span className="wpcf7-form-control-wrap textarea-525">
                        <textarea
                          name="message"
                          cols="40"
                          rows="10"
                          className="wpcf7-form-control wpcf7-textarea input-text"
                          aria-invalid="false"
                          placeholder="Write Your Message"
                        ></textarea>
                      </span>
                    </p>
                  </div>
                  <div className="form-schedule-submit">
                    {" "}
                    <input
                      type="submit"
                      value="Submit"
                      className="wpcf7-form-control wpcf7-submit btn btn-block"
                    />
                    <span className="ajax-loader"></span>{" "}
                  </div>
                </div>
                <div className="wpcf7-response-output wpcf7-display-none"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
