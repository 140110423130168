import React, { Component } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
class Map extends Component {
  render() {
    const GoogleMapExample = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap defaultCenter={{ lat: 33.8679673, lng: -118.0840555 }} defaultZoom={14}>
          {props.isMarkerShown && <Marker position={{ lat: 33.8679673, lng: -118.0840555 }} />}
        </GoogleMap>
      ))
    );
    return (
      <div className="map__wrapper">
        <GoogleMapExample
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCnRc0Sx5t3ZZXInBKLUNfU9DpVeYjRAMs&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className="map__inner" />}
          mapElement={<div style={{ height: `100%` }} />}
          isMarkerShown
        />
      </div>
    );
  }
}
export default Map;
